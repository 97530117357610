import ImageUpload from '../ImageUpload.js';

export default function FormEjercicio({ exerciseData, handleChange, image }) {
    return (
        <div className="max-w-3xl lg:min-w-96 h-full p-6 bg-white shadow-md md:rounded-lg flex flex-col">
            <div className="flex flex-col md:flex-row">
                <div className='md:w-64 mr-4'>
                    <div className='mb-2'>
                        <label className="block">
                            <span className="text-gray-700">Nombre:</span>
                            <input
                                type="text"
                                name="nombre"
                                value={exerciseData.nombre}
                                onChange={handleChange}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                                required
                            />
                        </label>
                    </div>
                    <div className='mb-2'>
                        <label className="block">
                            <span className="text-gray-700">Descripción:</span>
                            <textarea
                                name="descripcion"
                                value={exerciseData.descripcion}
                                onChange={handleChange}
                                rows="4"
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                            ></textarea>
                        </label>
                    </div>
                    <div className='mb-2'>
                        <ImageUpload setImage={image[0]} imagePreview={image[1]} setImagePreview={image[2]} />
                    </div>
                </div>
                <div className='md:w-64'>
                    <div className='mb-2'>
                        <label className="block">
                            <span className="text-gray-700">Feedback:</span>
                            <textarea
                                name="feedback"
                                value={exerciseData.feedback}
                                onChange={handleChange}
                                rows="4"
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                            ></textarea>
                        </label>
                    </div>
                    <div className='mb-2'>
                        <label className="block">
                            <span className="text-gray-700">Objetivos:</span>
                            <textarea
                                name="objetivos"
                                value={exerciseData.objetivos}
                                onChange={handleChange}
                                rows="4"
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                            ></textarea>
                        </label>
                    </div>
                    <div className='mb-2'>
                        <label className="block">
                            <span className="text-gray-700">Variaciones:</span>
                            <textarea
                                name="variaciones"
                                value={exerciseData.variaciones}
                                onChange={handleChange}
                                rows="4"
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                            ></textarea>
                        </label>
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row w-full">
                <div className='mb-2'>
                    <label className="block">
                        <span className="text-gray-700">Enlace:</span>
                        <input
                            name="enlace"
                            value={exerciseData.enlace}
                            onChange={handleChange}
                            rows="4"
                            className="mt-1 block w-full md:w-64 mr-4 border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                        ></input>
                    </label>
                </div><div className='mb-2'>
                    <label className="block">
                        <span className="text-gray-700">Bibliografia:</span>
                        <input
                            name="bibliografia"
                            value={exerciseData.bibliografia}
                            onChange={handleChange}
                            rows="4"
                            className="mt-1 block w-full md:w-64 border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                        ></input>
                    </label>
                </div>
            </div>
        </div>
    );
}