import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/communicationManager.js';
import ArrowBack from './components/ArrowBack.js';

export default function Login() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [eye, setEye] = useState(true);

    const loginUser = async (e) => {
        e.preventDefault();

        await login(username, password)
            .then(() => {
                navigate('/dashboard');
            })
            .catch(() => {
                setErrorMessage('El usuario no existe o la contraseña es incorrecta');
            });
    }


    return (
        <div className="bg-white h-full flex justify-center items-center md:background-sphere">
            <div className='absolute top-4 left-6'>
                <ArrowBack route={"/"} />
            </div>
            <div className="w-full max-w-md">
                <div className="md:bg-white md:shadow-2xl md:rounded-lg px-12 pt-6 pb-8">
                    <h2 className="text-2xl font-bold text-gray-800 mb-8">Login</h2>
                    <form onSubmit={loginUser}>
                        <div className="mb-4">
                            <label htmlFor="username" className="block text-gray-700 font-bold mb-2">
                                Username
                            </label>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="password" className="block text-gray-700 font-bold mb-2">
                                Password
                            </label>
                            <div className="relative">
                                <input
                                    type={eye ? "password" : "text"}
                                    className="w-full pl-3 pr-10 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500"
                                    placeholder="Password"
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <div className='absolute h-full flex items-center right-3 top-0'>
                                    {eye ? <span className={"icon-[tabler--eye] size-5"} onClick={() => setEye(!eye)} /> :
                                        <span className={"icon-[tabler--eye-closed] size-5"} onClick={() => setEye(!eye)} />}
                                </div>
                            </div>
                        </div>
                        <p className="error-message">{errorMessage}</p>
                        <button
                            type="submit"
                            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mt-2"
                        >
                            Login
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
