import React, { useEffect, useState } from 'react';

const ProgressBar = ({ duration, actionHandler, color }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 101) {
                    actionHandler();
                    return 100;
                } else {
                    return prev + 1; // Incrementamos el progreso 1% cada 30ms
                }
            });
        }, duration);

        return () => clearInterval(interval); // Limpiamos el intervalo al desmontar el componente
    }, []);

    return (
        <div className="mt-3 mb-1 h-1 !ml-0 bg-slate-600 rounded-lg w-full">
            <span
                className="h-full rounded-lg block"
                style={{ width: `${progress}%`, backgroundColor: color, marginLeft: 0 }}
            ></span>
        </div>
    );
};

export default ProgressBar;
