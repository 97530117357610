import React, { useState, useRef } from 'react';

export default function ImageUpload({ setImage, imagePreview, setImagePreview }) {
    const fileInputRef = useRef(null);

    const handleChange = (event) => {
        const { files } = event.target;
        if (files.length > 0) {
            const file = files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                setImagePreview(reader.result);
            };

            reader.readAsDataURL(file);
            setImage(file);
        }
    };

    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <div>
            <label className="block">
                <span className="text-gray-700">Imagen:</span>
                <div
                    className="mt-1 w-full h-48 border-2 border-gray-300 rounded-md shadow-sm focus-within:border-blue-500 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-50 center-content cursor-pointer"
                    style={{
                        backgroundImage: `url(${imagePreview})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        opacity: imagePreview ? 0.5 : 1
                    }}
                    onClick={handleClick}
                >
                    {!imagePreview && <span className="text-gray-500">Haz clic o arrastra una imagen aquí</span>}
                </div>
                <input
                    type="file"
                    name="image"
                    ref={fileInputRef}
                    accept="image/*"
                    onChange={handleChange}
                    className="hidden"
                    onClick={(e) => e.stopPropagation()}
                />
            </label>
        </div>
    );
}
