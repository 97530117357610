import React, { useEffect, useState } from 'react';
import ProgressBar from './ProgressBar';

export default function Alert({ title, setShowAlert, success }) {
    const [isVisible, setIsVisible] = useState(false); // Controla la visibilidad con opacidad

    useEffect(() => {
        setIsVisible(true); // Mostrar la alerta con animación al montar el componente

        const timeout = setTimeout(() => {
            setIsVisible(false); // Ocultar la alerta antes de desmontarla
            setTimeout(() => setShowAlert(false), 500); // Desmonta el componente después de la animación
        }, 3000); // Duración antes de desaparecer (3 segundos)

        return () => clearTimeout(timeout); // Limpiar el timeout si el componente se desmonta
    }, [setShowAlert]);

    return (
        <div
            className={`fixed top-0 right-0 m-4 ${success ? "bg-green-500" : "bg-red-500"} text-white p-4 rounded-lg shadow-lg flex items-center flex-col space-x-4 transition-all duration-500 transform ${isVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-90'
                }`}
            style={{ transitionTimingFunction: 'ease-in-out' }} // Control de la curva de animación
        >
            <div className='flex'>
                <span className={`${!success ? "icon-[bx--error]" : "icon-[bx--check-circle]"} text-2xl`}></span>
                <p className="flex-1 ml-1">{title}</p>
            </div>
            <ProgressBar duration={30} actionHandler={() => setIsVisible(false)} color={"white"} />
        </div>
    );
}
