import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from './Modal';
import { deleteExercise } from '../../services/communicationManager.js';

export default function DeleteExercise({ exerciseId, isVisible, setIsVisible, showDeleteModal, setShowDeleteModal }) {
    const [isExerciseDeleted, setIsExerciseDeleted] = useState(false);
    const navigate = useNavigate();

    const handleConfirm = () => {
        setIsVisible(false);
        setShowDeleteModal(false);

        deleteExercise(exerciseId)
            .then(data => {
                setIsExerciseDeleted(true);
                navigate("/exercises");
            })
            .catch(() => {
                console.log("No se ha podido eliminar el ejercicio");
            });
    };

    return (
        <>
            {showDeleteModal && (
                <div className={`absolute top-0 center-content w-full h-full`}>
                    <div className={`absolute w-full h-full bg-black transition-all duration-500 transform
                    ${isVisible ? 'opacity-25' : 'opacity-0'}`}
                        style={{ transitionTimingFunction: 'ease-in-out' }}></div>
                    <div
                        className={`gap-4 p-8 bg-white rounded-lg transition-all duration-500 transform 
                            ${isVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-90'}`}
                        style={{ transitionTimingFunction: 'ease-in-out' }}
                    >
                        <p className="text-xl font-medium">¿Estás seguro de eliminar el siguiente ejercicio?</p>

                        <div className="mt-4 flex justify-end space-x-4">
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition"
                                onClick={handleConfirm}
                            >
                                Confirmar
                            </button>
                            <button
                                className="bg-gray-300 px-4 py-2 rounded-lg hover:bg-gray-400 transition"
                                onClick={() => {
                                    setIsVisible(false);
                                    setTimeout(() => {
                                        setShowDeleteModal(false);
                                    }, 500);
                                }}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isExerciseDeleted && (
                <Modal title={"El ejercicio se ha eliminado correctamente"} isProgressActive={true} />
            )}
        </>
    );
}
