export default function FormJuegoColectivo({ exerciseData, handleChange }) {
    return (
        <div className="flex flex-col md:flex-row gap-5 mb-4">
            <div className='w-full md:w-64 md:48'>
                <div className='mb-2'>
                    <label className="block">
                        <span className="text-gray-700">Fase:</span>
                        <select
                            name="fase"
                            value={exerciseData.fase}
                            onChange={handleChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                        >
                            <option value="1">Ataque</option>
                            <option value="2">Defensa</option>
                            <option value="3">Contraataque</option>
                            <option value="4">Repliegue</option>
                        </select>
                    </label>
                </div>
                <div className='mb-2'>
                    <label className="block">
                        <span className="text-gray-700">2nda Fase:</span>
                        <select
                            name="segunda_fase"
                            value={exerciseData.segunda_fase}
                            onChange={handleChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                        >
                            <option value="">Seleccionar...</option>
                            <option value="1">Ataque</option>
                            <option value="2">Defensa</option>
                            <option value="3">Contraataque</option>
                            <option value="4">Repliegue</option>
                        </select>
                    </label>
                </div>
                <div className='mb-2'>
                    <label className="block">
                        <span className="text-gray-700">Sistema:</span>
                        <select
                            name="sistema"
                            value={exerciseData.sistema}
                            onChange={handleChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                        >
                            <option value="">Seleccionar...</option>
                            <option value="6:0">6:0</option>
                            <option value="5:1">5:1</option>
                            <option value="3:2:1">3:2:1</option>
                            <option value="4:2">4:2</option>
                            <option value="Individual">Individual</option>
                            <option value="Superioridad">Superioridad</option>
                            <option value="Inferioridad">Inferioridad</option>
                        </select>
                    </label>
                </div>
                <div className="flex justify-between mb-2">
                    <label className="block">Atacantes:</label>
                    <input id="attackers" type="number" min="0" max="9" placeholder="0-10" className="flex h-10 rounded-md border border-input bg-background px-3 ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 w-20" />
                </div>
                <div className="flex justify-between">
                    <label className="block">Defensores:</label>
                    <input id="defenders" type="number" min="0" max="9" placeholder="0-10" className="flex h-10 rounded-md border border-input bg-background px-3 ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 w-20" />
                </div>
            </div>
            <div className='w-full md:w-64 md:48'>
                <div className='mb-2'>
                    <label className="block">
                        <span className="text-gray-700">Medio Simple:</span>
                        <select
                            name="medio_simple"
                            value={exerciseData.medio_simple}
                            onChange={handleChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                        >
                            <option value="">Seleccionar...</option>
                            <option value="Asimetría">Asimetría</option>
                            <option value="Circulación de balón">Circulación de balón</option>
                            <option value="Circulaciones">Circulaciones</option>
                            <option value="Transformaciones">Transformaciones</option>
                            <option value="Basculación defensiva">Basculación defensiva</option>
                            <option value="Distribución inicial de responsabilidades defensivas">Distribución inicial de responsabilidades defensivas</option>
                            <option value="Cobertura defensiva">Cobertura defensiva</option>
                        </select>
                    </label>
                </div>
                <div className='mb-2'>
                    <label className="block">
                        <span className="text-gray-700">Medio Básico:</span>
                        <select
                            name="medio_basico"
                            value={exerciseData.medio_basico}
                            onChange={handleChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                        >
                            <option value="">Seleccionar...</option>
                            <option value="Pase y va">Pase y va</option>
                            <option value="Penetraciones sucesivas">Penetraciones sucesivas</option>
                            <option value="Cruces">Cruces</option>
                            <option value="Cortinas">Cortinas</option>
                            <option value="Permuta">Permuta</option>
                            <option value="Bloqueo">Bloqueo</option>
                            <option value="Pantallas">Pantallas</option>
                        </select>
                    </label>
                </div>
                <div className='mb-2'>
                    <label className="block">
                        <span className="text-gray-700">Medio Reactivo:</span>
                        <select
                            name="medio_reactivo"
                            value={exerciseData.medio_reactivo}
                            onChange={handleChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                        >
                            <option value="">Seleccionar...</option>
                            <option value="Barrera dinámica">Barrera dinámica</option>
                            <option value="Deslizamiento">Deslizamiento</option>
                            <option value="Cambio de oponente">Cambio de oponente</option>
                            <option value="Contrabloqueo">Contrabloqueo</option>
                            <option value="Defensa del pase y va">Defensa del pase y va</option>
                            <option value="Doblaje">Doblaje</option>
                            <option value="Bloqueo/Colaboración">Bloqueo/Colaboración</option>
                        </select>
                    </label>
                </div>
                <div className='mb-2'>
                    <label className="block">
                        <span className="text-gray-700">Medio Activo:</span>
                        <select
                            name="medio_activo"
                            value={exerciseData.medio_activo}
                            onChange={handleChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                        >
                            <option value="">Seleccionar...</option>
                            <option value="2 contra 1">2 contra 1</option>
                            <option value="Ataque al impar">Ataque al impar</option>
                            <option value="Flotación">Flotación</option>
                            <option value="Ataque al par">Ataque al par</option>
                        </select>
                    </label>
                </div>
                <div className='mb-2'>
                    <label className="block">
                        <input
                            type="checkbox"
                            name="contragol"
                            checked={exerciseData.contragol}
                            onChange={handleChange}
                            className="mr-2"
                        />
                        <span className="text-gray-700">Contragol</span>
                    </label>
                </div>
            </div>
        </div>
    )
}