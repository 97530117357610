import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBack from './components/ArrowBack.js';
import ProfileButton from './components/ProfileButton.js';
import { getExercises } from '../services/communicationManager.js';

export default function Exercises() {
    const navigate = useNavigate();
    const [exercises, setExercises] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [imagePreview, setImagePreview] = useState(null);
    const [page, setPage] = useState(0);
    const tipos = [
        "Juego Colectivo",
        "Tec-tac",
        "Juego",
        "Preparación Física",
        "Coordinación"
    ];
    const fases = [
        "",
        "Ataque",
        "Defensa",
        "Contrataque",
        "Repliegue"
    ];

    useEffect(() => {
        getExercises(page)
            .then(data => {
                setExercises(data);
                console.log(data);
                if (data.length == 10) {
                    setButtonDisabled(false);
                }
            })
            .catch(() => {
                console.log("No se han podido obtener los ejercicios")
            });
    }, []);

    const nextPage = () => {
        if (buttonDisabled == false) {
            doFetchExercises(page + 1);
        }
    }

    const prevPage = () => {
        if (page != 0) {
            doFetchExercises(page - 1);
        }
    }

    const doFetchExercises = (selectedPage) => {
        setExercises(false);
        getExercises(selectedPage)
            .then(data => {
                setExercises(data);
                console.log(data);
                setPage(selectedPage);
                if (data.length == 10) {
                    setButtonDisabled(false);
                } else {
                    setButtonDisabled(true);
                }
            })
            .catch(() => {
                console.log("No se han podido obtener los ejercicios")
            });
    }

    return (
        <div className="background-sphere md:!items-start !justify-start">
            <div className='head-mobile-buttons md:head-buttons'>
                <div className='flex gap-4'>
                    <ArrowBack route={"/dashboard"} />
                    <button className='nav-button bg-white shadow-2xl text-slate-700' onClick={() => navigate("/create-exercises")}>Crear ejercicios</button>
                </div>
                <ProfileButton />
            </div>
            <div className='w-full h-fit min-h-full flex flex-col overflow-auto md:px-24 px-10 justify-between'>
                <div className={"flex flex-col justify-center mt-4 w-full bg-white p-6 rounded-lg xl:grid xl:grid-cols-2 2xl:grid-cols-3 gap-6" +
                    (!exercises ? "h-full" : "")}>
                    {exercises ?
                        exercises.length != 0 ? (
                            exercises.map((exercise, index) => {
                                return (
                                    // <div className='center-content'>
                                    //     <div className='shadow-2xl bg-white rounded-lg h-fit cursor-pointer w-64' key={exercise.id}
                                    //         onClick={() => navigate("/exercise", { state: { exercise: exercise } })}>
                                    //         <img className='rounded-t-lg h-48 w-full object-contain' src={exercise.imagen} alt={exercise.nombre}></img>
                                    //         <div className='p-4'>
                                    //             <h2 className='font-bold'>{exercise.nombre}</h2>
                                    //             <div className='flex flex-wrap gap-2 mt-2'>
                                    //                 <p className='px-3 py-1 bg-blue-300 rounded-full'>{tipos[exercise.tipo]}</p>
                                    //                 {exercise.tipo == 0 ? (
                                    //                     <p className='px-3 py-1 bg-green-300 rounded-full'>{fases[exercise.fase_joc_colectiu]}</p>
                                    //                 ) : (
                                    //                     <p className='px-3 py-1 bg-green-300 rounded-full'>{exercise.rol_tec_tac}</p>
                                    //                 )}
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div>
                                    <div className='rounded-md p-4 bg-gray-100 shadow-md flex flex-col sm:flex-row justify-between w-full sm:h-36' key={index}>
                                        <div className='sm:w-[70%] 2xl:w-[60%] w-full h-full flex flex-col justify-between cursor-pointer '
                                            onClick={() => navigate("/exercise", { state: { exercise: exercise } })}>
                                            <div>
                                                <h2 className='font-bold'>{exercise.nombre}</h2>
                                                <p className='sm:multi-line-truncate text-ellipsis overflow-hidden'>{exercise.descripcion}</p>
                                            </div>
                                            <div className='flex flex-wrap text-xs text-white gap-2 mt-2 sm:mt-0'>
                                                <p className='px-3 py-1 bg-blue-400 rounded-full h-fit'>{tipos[exercise.tipo]}</p>
                                                {exercise.tipo == 0 ? (
                                                    <p className='px-3 py-1 bg-green-400 rounded-full h-fit'>{fases[exercise.fase_joc_colectiu]}</p>
                                                ) : (
                                                    <p className='px-3 py-1 bg-green-400 rounded-full h-fit'>{exercise.rol_tec_tac}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className='sm:w-[30%] overflow-hidden center-content sm:!justify-end sm:mt-0 mt-4'>
                                            <div className='relative h-full'>
                                                <div className='w-full h-full absolute bg-black bg-opacity-0 opacity-0 hover:opacity-100 hover:bg-opacity-50 rounded-md center-content transition-all delay-50'
                                                    onClick={() => setImagePreview(exercise.imagen)}>
                                                    <p className='text-white'>Vista previa</p>
                                                </div>
                                                <img className='rounded-md object-contain h-full' src={exercise.imagen} alt={exercise.nombre}></img>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <div className='flex items-center jsutify-center'>
                                <h1>No hay mas ejercicios que mostrar</h1>
                            </div>
                        )
                        : (
                            <div className='w-full center-content'>
                                <span className="icon-[line-md--loading-loop] text-black size-10"></span>
                            </div>
                        )}
                </div>
                {exercises && (
                    <div className="flex justify-center py-6">
                        <div className='flex justify-center mt-4 gap-4'>
                            <button className={'rounded-button ' +
                                (page == 0 ? "text-slate-400 !cursor-not-allowed" : "text-black hover:bg-white")}
                                onClick={prevPage}>
                                <span className="icon-[material-symbols--arrow-back-ios-new-rounded]"></span>
                            </button>
                            <button className={'rounded-button ' +
                                (!buttonDisabled ? "text-black hover:bg-white" : "text-slate-400 !cursor-not-allowed")}
                                onClick={nextPage}>
                                <span className="icon-[material-symbols--arrow-forward-ios-rounded]"></span>
                            </button>
                        </div>
                    </div>
                )}
                {imagePreview != null && (
                    <div className='fixed w-full h-full bg-black bg-opacity-50 z-10 top-0 left-0 center-content'
                        onClick={() => setImagePreview(null)}>
                        <img className="h-[60%]" src={imagePreview} alt="Previsualización de la imagen"></img>
                    </div>
                )}
            </div>
        </div >
    )
}