import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBack from './components/ArrowBack.js';
import ProfileButton from './components/ProfileButton.js';
import { saveCSVExercises } from '../services/communicationManager.js';
import Alert from './components/Alert.js';

export default function CSVLoader() {
    const navigate = useNavigate();
    const fileInputRefs = useRef([]); // Array de referencias
    const imageErrorTimeout = useRef(null);
    const [fileName, setFileName] = useState(null);
    const [jsonData, setJsonData] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imageError, setImageError] = useState(false);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);

            // Lógica para leer y procesar el archivo CSV
            const reader = new FileReader();
            reader.onload = (e) => {
                const csvData = e.target.result;
                const json = csvToJson(csvData);
                setJsonData(json);
                console.log('JSON generado:', json);
            };
            reader.readAsText(file);
        }
    };

    const uploadExercises = async () => {
        if (jsonData.every(ejercicio => ejercicio.imagen != "")) {
            setLoading(true);
            const formData = new FormData();

            formData.append('exercises', JSON.stringify(jsonData));
            jsonData.forEach((exercise, index) => {
                if (exercise.imagen) {
                    formData.append(`imagen[${index}]`, exercise.imagen);
                }
            });

            await saveCSVExercises(formData)
                .then(() => {
                    setShowAlert(true);
                    setLoading(false);
                    setTimeout(() => {
                        navigate("/exercises");
                    }, 2000);
                })
                .catch(() => {
                    console.log("No se ha podido crear el ejercicio");
                    setLoading(false);
                });
        } else {
            setImageError(true);

            if (imageErrorTimeout.current) {
                clearTimeout(imageErrorTimeout.current);
            }

            imageErrorTimeout.current = setTimeout(() => {
                setImageError(false);
                imageErrorTimeout.current = null;
            }, 4000);
        }
    };


    const csvToJson = (csv) => {
        const lines = csv.split("\n");
        const headers = lines[0].split(",");
        const result = [];

        for (let i = 1; i < lines.length; i++) {
            const row = lines[i].split(",");
            if (row.length === headers.length) {
                const obj = {};
                headers.forEach((header, index) => {
                    obj[header.trim()] = row[index]?.trim();
                });
                result.push(obj);
            }
        }
        return result;
    };

    const handleClick = (index) => {
        if (fileInputRefs.current[index]) {
            fileInputRefs.current[index].click();
        }
    };

    const setImageInJSON = (event, index) => {
        const { files } = event.target;

        if (files.length > 0) {
            const file = files[0];
            const reader = new FileReader();

            reader.readAsDataURL(file);

            setJsonData((prevJsonData) => {
                const updatedJson = [...prevJsonData];
                updatedJson[index].imagen = file;
                return updatedJson;
            });
        }
    };

    return (
        <div className="background-sphere md:!items-start !justify-start">
            <div className='head-mobile-buttons md:head-buttons'>
                <div className='flex gap-4'>
                    <ArrowBack route={"/create-exercises"} />
                    <button className='nav-button bg-white shadow-2xl text-slate-700' onClick={() => navigate("/create-exercises")}>Crear ejercicios</button>
                </div>
                <ProfileButton />
            </div>
            <div className='w-full h-full center-content pb-16'>
                <div className="bg-white relative p-8 rounded-lg shadow-lg text-center max-h-[90%]">
                    <h2 className="mb-4 text-gray-800 text-xl font-semibold">
                        Carga tu archivo CSV
                    </h2>
                    <label
                        htmlFor="csv-upload"
                        className="inline-block px-6 py-3 bg-blue-500 text-white rounded-md cursor-pointer transition duration-300 hover:bg-blue-600"
                    >
                        {fileName ? fileName : 'Seleccionar archivo CSV'}
                        <input
                            type="file"
                            id="csv-upload"
                            accept=".csv"
                            onChange={handleFileUpload}
                            className="hidden"
                        />
                    </label>
                    {fileName && (
                        <p className="mt-4 text-blue-500">
                            Archivo cargado: {fileName}
                        </p>
                    )}
                    {jsonData && (
                        <div>
                            {jsonData.map((exercise, index) => (
                                <div className='w-[600px] rounded-md p-2 mt-2 bg-slate-200 flex flex-row justify-between' key={index}>
                                    <p>{exercise.nombre}</p>
                                    <div className='flex flex-row'>
                                        <div
                                            className={`px-3 py-1 bg-green-500 text-white rounded-md cursor-pointer transition duration-300 hover:bg-green-600 mr-3`}
                                            onClick={() => handleClick(index)}
                                        >
                                            {jsonData[index].imagen ? "Cambiar imagen" : "Añade una imagen"}
                                        </div>
                                        <input
                                            type="file"
                                            name="image"
                                            ref={(el) => fileInputRefs.current[index] = el} // Asignar cada ref en función del índice
                                            accept="image/*"
                                            onChange={(e) => setImageInJSON(e, index)}
                                            className="hidden"
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                        <button
                                            onClick={() => console.log("Eliminar")}
                                            className='px-3 py-1 bg-red-500 text-white rounded-md cursor-pointer transition duration-300 hover:bg-red-600'
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <button className='nav-button bg-blue-500 hover:bg-blue-700 mt-2'
                                onClick={() => uploadExercises()}>Cargar ejercicios</button>
                        </div>
                    )}
                    {loading && (
                        <div className='absolute rounded-lg top-0 left-0 z-10 size-full bg-black opacity-50 flex items-center justify-center transition-all duration-500 transform'>
                            <span className="icon-[line-md--loading-loop] text-white size-10"></span>
                        </div>
                    )}
                </div>
            </div>
            {showAlert && (
                <Alert title={"Se han cargado correctamente"} isProgressActive={false} setShowAlert={setShowAlert} success={true} />
            )}
            {imageError && (
                <Alert title={"Faltan imagenes"} isProgressActive={false} setShowAlert={setShowAlert} />
            )}
        </div >
    );
}
