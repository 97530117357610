export async function login(username, password) {
    return await new Promise(function (resolve, reject) {
        fetch(`${process.env.REACT_APP_API_URL}/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ username, password })
        })
            .then(response => response.json())
            .then(data => {
                if (!data.error) {
                    resolve();
                } else {
                    reject(new Error('El usuario no existe o la contraseña es incorrecta'));
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

export async function saveExercise(exercise) {
    return await new Promise(function (resolve, reject) {
        fetch(`${process.env.REACT_APP_API_URL}/save-exercise`, {
            method: "POST",
            body: exercise
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (!data.error) {
                    resolve(true);
                } else {
                    reject(false);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

export async function saveCSVExercises(csv) {
    return await new Promise(function (resolve, reject) {
        fetch(`${process.env.REACT_APP_API_URL}/save-csv-exercises`, {
            method: "POST",
            body: csv
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (!data.error) {
                    resolve(true);
                } else {
                    reject(false);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

export async function deleteExercise(id) {
    return await new Promise(function (resolve, reject) {
        fetch(`${process.env.REACT_APP_API_URL}/delete-exercise/${id}`, {
            method: "DELETE"
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (!data.error) {
                    resolve(true);
                } else {
                    reject(false);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

export async function getExercises(page) {
    return await new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/get-exercises/${page}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                if (!data.error) {
                    resolve(data);
                } else {
                    reject(data);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

export async function getCompleteExercise(id, tipo) {
    return await new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/get-complete-exercise/${id}/${tipo}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                if (!data.error) {
                    resolve(data);
                } else {
                    reject(data);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}