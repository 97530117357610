import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBack from './components/ArrowBack.js';
import ProfileButton from './components/ProfileButton.js';

export default function Dashboard() {
    const navigate = useNavigate();

    return (
        <div className="background-sphere md:!items-start !justify-start">
            <div className='head-mobile-buttons md:head-buttons'>
                <div className='flex gap-4'>
                    <button className='nav-button bg-white shadow-2xl text-slate-700' onClick={() => navigate("/")}>Menú principal</button>
                </div>
                <ProfileButton />
            </div>
            <div className='w-full h-full center-content pb-16'>
                <div className="bg-white shadow-2xl rounded-lg px-10 py-6">
                    <div className="grid grid-cols-2 gap-8">
                        <div className="flex flex-col items-center">
                            <button variant="ghost" size="icon" className="rounded-button" onClick={() => navigate("/exercises")}>
                                <span className="icon-[mingcute--barbell-line] size-6"></span>
                            </button>
                            <span className="text-md">Ejercicios</span>
                        </div>
                        <div className="flex flex-col items-center">
                            <button variant="ghost" size="icon" className="rounded-button">
                                <span className="icon-[lucide--clipboard] size-6"></span>
                            </button>
                            <span className="text-md">Entrenos</span>
                        </div>
                        <div className="flex flex-col items-center">
                            <button variant="ghost" size="icon" className="rounded-button">
                                <span className="icon-[tabler--users] size-6"></span>
                            </button>
                            <span className="text-md">Equipos</span>
                        </div>
                        <div className="flex flex-col items-center">
                            <button variant="ghost" size="icon" className="rounded-button">
                                <span className="icon-[tabler--calendar] size-6"></span>
                            </button>
                            <span className="text-md">Temporadas</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}