import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './pages/App';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Exercises from './pages/Exercises';
import Exercise from './pages/Exercise';
import CreateExercises from './pages/CreateExercises';
import CSVLoader from './pages/CSVLoader';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/exercises" element={<Exercises />} />
        <Route path="/exercise" element={<Exercise />} />
        <Route path="/create-exercises" element={<CreateExercises />} />
        <Route path="/csv-loader" element={<CSVLoader />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode >
);
