import React from 'react';

export default function FormTecTac({ exerciseData, handleChange }) {

    const handlePositionToggle = (position) => {
        const updatedPositions = exerciseData.posiciones.includes(position)
            ? exerciseData.posiciones.filter((pos) => pos !== position)
            : [...exerciseData.posiciones, position];

        // Llamamos a handleChange para actualizar el estado
        handleChange({ target: { name: 'posiciones', value: updatedPositions } });
    };

    return (
        <div className="flex flex-col md:flex-row gap-5 mb-4">
            <div className="md:w-64 w-full md:48">
                <div className='mb-2'>
                    <label className="block">
                        <span className="text-gray-700">Rol:</span>
                        <select
                            name="rol"
                            value={exerciseData.rol}
                            onChange={handleChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                        >
                            <option value="Ofensiva">Ofensiva</option>
                            <option value="Defensiva">Defensiva</option>
                        </select>
                    </label>
                </div>
                <div className='mb-2'>
                    <label className="block">
                        <span className="text-gray-700">Posiciones:</span>
                    </label>
                    <div className="flex flex-wrap gap-2 mt-1">
                        {['Extremo', 'Lateral', 'Central', 'Pivote', 'Portero'].map((position) => (
                            <button
                                key={position}
                                type="button"
                                onClick={() => handlePositionToggle(position)}
                                className={`px-3 py-2 border rounded-md shadow-sm focus:outline-none 
                                ${exerciseData.posiciones.includes(position) ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                            >
                                {position}
                            </button>
                        ))}
                    </div>
                </div>
                <div className='mb-2'>
                    <label className="block">
                        <span className="text-gray-700">Contenidos:</span>
                        <input
                            type="text"
                            name="contenidos"
                            value={exerciseData.contenidos}
                            onChange={handleChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                        />
                    </label>
                </div>
            </div>
        </div>
    );
};
