import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBack from './components/ArrowBack.js';
import ProfileButton from './components/ProfileButton.js';
import DeleteExercise from './components/DeleteExercise.js';
import Labels from './components/Labels.js';
import { getCompleteExercise } from '../services/communicationManager.js';

export default function Exercise() {
    const navigate = useNavigate();
    const location = useLocation();
    const [exercise, setExercise] = useState(location.state.exercise);
    const [isFullExercise, setIsFullExercise] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (location.state.exercise == null || location.state.exercise == undefined) {
            navigate("/exercises");
        } else {
            getAditions();
        }
    }, []);

    async function getAditions() {
        console.log(exercise.id, exercise.tipo);
        const additions = await getCompleteExercise(exercise.id, exercise.tipo);
        setExercise(prevExercise => ({
            ...prevExercise,
            ...additions,
        }));
        setIsFullExercise(true);
    }

    const handleDeleteClick = () => {
        setShowDeleteModal(true);
        setIsVisible(true);
    };

    return (
        <div className="background-sphere md:!items-start !justify-start">
            <div className='head-mobile-buttons md:head-buttons'>
                <div className='flex gap-4'>
                    <ArrowBack route={"/exercises"} />
                    {isFullExercise && (
                        <button className='nav-button bg-white shadow-2xl text-slate-700'
                            onClick={() => navigate("/create-exercises", { state: { exercise: exercise } })}>Editar ejercicio</button>
                    )}
                    {isFullExercise && (
                        <button
                            className="rounded-button"
                            onClick={handleDeleteClick}
                        >
                            <span className="icon-[mdi--trash-can-outline] size-6"></span>
                        </button>
                    )}
                </div>
                <ProfileButton />
            </div>
            <div className='w-full h-fit min-h-full flex flex-col overflow-auto md:px-24 px-10 justify-between'>
                <div className="py-5">
                    <div className='flex gap-4 flex-col md:flex-row'>
                        <div className='rounded-md md:h-64 md:min-w-72 md:max-w-72 h-48 w-full bg-white flex justify-center'>
                            <img className='h-full object-contain' src={exercise.imagen} alt={exercise.nombre}></img>
                        </div>
                        <div className='rounded-md bg-white p-4 w-full flex flex-col gap-1'>
                            <h2 className='font-bold'>{exercise.nombre}</h2>
                            <p>{exercise.descripcion}</p>
                            <p className='mt-2 font-semibold'>Objetivos</p>
                            <p>{exercise.objetivos}</p>
                            <div className="flex gap-2 h-full items-end">
                                <a href={exercise.enlace} className='text-blue-500 underline' target="_blank">Ver Recurso</a>
                                <p>{exercise.bibliografia}</p>
                            </div>
                        </div>
                    </div>
                    {isFullExercise && (
                        <Labels exercise={exercise} />
                    )}
                    <div className='bg-white rounded-lg h-fit mt-4'>
                        <div className='p-4'>
                            <div className='grid grid-cols-1 md:grid-cols-2'>
                                {exercise.variaciones != null && (
                                    <div className='md:pr-4'>
                                        <h2 className='font-bold'>Variaciones:</h2>
                                        <p>{exercise.variaciones}</p>
                                    </div>
                                )}
                                {exercise.feedback && (
                                    <div>
                                        <h2 className='font-bold'>Feedback:</h2>
                                        <p>{exercise.feedback}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showDeleteModal && (
                <DeleteExercise exerciseId={exercise.id} isVisible={isVisible} setIsVisible={setIsVisible} showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} />
            )}
        </div>
    )
}