import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function LandingPage() {
  const navigate = useNavigate();

  return (
    <div className="min-full-screen flex flex-col justify-between background-sphere">
      {/* Header */}
      <header className="py-4 text-white w-full">
        <div className="container mx-auto flex justify-between items-center px-4">
          <h1 className="text-xl sm:text-3xl font-bold text-gray-50">HShpere GUAU  Estadísticas</h1>
          <nav>
            <button
              onClick={() => navigate('/login')}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded text-sm sm:text-base"
            >
              Login
            </button>
          </nav>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow flex flex-col items-center justify-center text-center px-4 sm:px-8 mb-10 mt-4 2xl:mt-0">
        <div className="bg-white bg-opacity-90 p-6 sm:p-8 rounded-lg shadow-lg max-w-md sm:max-w-3xl">
          <h2 className="text-2xl sm:text-4xl font-bold text-gray-800 mb-4">
            Gestiona tus Entrenamientos como un Profesional
          </h2>
          <p className="text-gray-600 text-sm sm:text-lg mb-6">
            Guarda ejercicios, crea entrenamientos personalizados, analiza estadísticas de los
            jugadores y controla las temporadas de manera eficiente.
          </p>
          <button
            onClick={() => navigate('/register')}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 sm:py-3 px-4 sm:px-6 rounded-full text-sm sm:text-lg"
          >
            Regístrate Ahora
          </button>
        </div>
      </main>

      {/* Features Section */}
      <section className="bg-slate-800 py-8 sm:py-12">
        <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 text-center px-4">
          <div>
            <h3 className="text-lg sm:text-xl font-bold text-gray-100 mb-2">Guarda Ejercicios</h3>
            <p className="text-gray-300 text-sm sm:text-base">
              Crea y almacena ejercicios específicos para tus sesiones de entrenamiento.
            </p>
          </div>
          <div>
            <h3 className="text-lg sm:text-xl font-bold text-gray-100 mb-2">Crea Entrenamientos</h3>
            <p className="text-gray-300 text-sm sm:text-base">
              Diseña entrenamientos personalizados para cada jugador o equipo.
            </p>
          </div>
          <div>
            <h3 className="text-lg sm:text-xl font-bold text-gray-100 mb-2">Estadísticas de Jugadores</h3>
            <p className="text-gray-300 text-sm sm:text-base">
              Analiza el rendimiento y las estadísticas para optimizar tu equipo.
            </p>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-black bg-opacity-75 py-6 text-center text-white text-sm sm:text-base w-full">
        <p>&copy; 2024 HShpere. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}