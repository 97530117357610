import React, { useState, useEffect } from 'react';

export default function Labels({ exercise }) {
    useEffect(() => {
        console.log(exercise);
    }, []);

    const tipos = [
        "Juego Colectivo",
        "Tec-tac",
        "Juego",
        "Preparación Física",
        "Coordinación"
    ];
    const fases = [
        "",
        "Ataque",
        "Defensa",
        "Contrataque",
        "Repliegue"
    ];

    return (
        <div className='flex flex-wrap gap-2 text-white font-semibold text-sm h-fit mt-3'>
            <p className='px-3 bg-blue-400 rounded-full'>{tipos[exercise.tipo]}</p>
            {exercise.tipo == 0 ? (
                <div className='flex flex-wrap gap-2'>
                    {exercise.fase_joc_colectiu && (
                        <p className='px-3 bg-green-400 rounded-full'>
                            {fases[exercise.fase_joc_colectiu]}
                        </p>
                    )}
                    {exercise.segunda_fase && (
                        <p className='px-3 bg-red-400 rounded-full'>
                            {fases[exercise.segunda_fase]}
                        </p>
                    )}
                    {exercise.medio_simple && (
                        <p className='px-3 bg-yellow-500 rounded-full'>
                            {exercise.medio_simple}
                        </p>
                    )}
                    {exercise.medio_basico && (
                        <p className='px-3 bg-purple-400 rounded-full'>
                            {exercise.medio_basico}
                        </p>
                    )}
                    {exercise.medio_reactivo && (
                        <p className='px-3 bg-pink-400 rounded-full'>
                            {exercise.medio_reactivo}
                        </p>
                    )}
                    {exercise.medio_activo && (
                        <p className='px-3 bg-teal-400 rounded-full'>
                            {exercise.medio_activo}
                        </p>
                    )}
                    {exercise.sistema && (
                        <p className='px-3 bg-orange-400 rounded-full'>
                            {exercise.sistema}
                        </p>
                    )}
                    {exercise.contragol == 1 && (
                        <p className='px-3 bg-cyan-500 rounded-full'>
                            Contragol
                        </p>
                    )}
                </div>
            ) : exercise.tipo == 1 ? (
                <div className='flex flex-wrap gap-2'>
                    {exercise.rol_tec_tac && (
                        <p className='px-3 bg-green-400 rounded-full'>
                            {exercise.rol_tec_tac}
                        </p>
                    )}
                    {exercise.posiciones && (
                        <p className='px-3 bg-red-400 rounded-full'>
                            {exercise.posiciones}
                        </p>
                    )}
                    {exercise.contenidos && (
                        <p className='px-3 bg-yellow-400 rounded-full'>
                            {exercise.contenidos}
                        </p>
                    )}
                </div>
            ) : exercise.tipo == 2 ? (
                <div className='flex flex-wrap gap-2'>
                    {exercise.contenidos && (
                        <p className='px-3 bg-yellow-400 rounded-full'>
                            {exercise.contenidos}
                        </p>
                    )}
                </div>
            ) : (
                <></>
            )}

        </div>
    );
}