import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { saveExercise } from '../services/communicationManager.js';
import Modal from './components/Modal.js';
import Alert from './components/Alert.js';
import ArrowBack from './components/ArrowBack.js';
import FormEjercicio from './components/forms/FormEjercicio.js';
import FormJuegoColectivo from './components/forms/FormJuegoColectivo.js';
import FormTecTac from './components/forms/FormTecTac.js';

export default function CreateExercises() {
    const navigate = useNavigate();
    const location = useLocation();
    const editExercise = location.state ? location.state.exercise : null;
    const [imagePreview, setImagePreview] = useState('');
    const [exerciseData, setExerciseData] = useState({
        id: null,
        nombre: '',
        descripcion: '',
        tipo: 0,
        fase: 1,
        rol: "Ofensiva",
        imagen: '',
        feedback: '',
        objetivos: '',
        variaciones: '',
        enlace: '',
        bibliografia: '',
        posiciones: [],
        user_id: 1
    });
    const [modal, setModal] = useState(false);
    const [dataModal, setDataModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if (editExercise) {
            setExerciseData(replaceNullWithEmptyString(editExercise));
            setImagePreview(editExercise.imagen);
        }
    }, []);

    const replaceNullWithEmptyString = (obj) =>
        Object.fromEntries(
            Object.entries(obj).map(([key, value]) => [
                key,
                value === null ? "" : value
            ])
        );

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setExerciseData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const setImage = (file) => {
        setExerciseData(prevState => ({
            ...prevState,
            imagen: file
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (exerciseData.imagen == "") {
            setShowAlert(true);
        } else {
            setModal(true);

            const formData = new FormData();
            Object.keys(exerciseData).forEach(key => {
                formData.append(key, exerciseData[key]);
            });

            await saveExercise(formData)
                .then(() => {
                    if (editExercise) {
                        setDataModal("Ejercicio editado correctamente");
                    } else {
                        setDataModal("Ejercicio creado correctamente");
                    }
                })
                .catch(() => {
                    setDataModal("No se ha podido crear el ejercicio");
                });
        }
    }

    return (
        <div className='background-sphere'>
            <div className='head-mobile-buttons md:head-buttons'>
                <ArrowBack route={"/exercises"} />
                <button className='nav-button bg-white shadow-2xl text-slate-700' onClick={() => navigate("/csv-loader")}>Cargar CSV</button>
            </div>
            <div className='flex flex-col justify-center h-fit min-h-full items-center overflow-scroll md:overflow-hidden pb-5 sm:p-0 md:py-5 2xl:p-0'>
                <div className='md:mb-5 p-4 bg-white shadow-md md:rounded-lg w-full md:w-fit text-center md:z-0 z-10'>
                    {editExercise == null ? (<h2 className="text-xl font-semibold">Crear Ejercicio</h2>) : (<h2 className="text-xl font-semibold">Editar Ejercicio</h2>)}
                </div>
                <form onSubmit={handleSubmit} className="h-fit w-full md:w-fit">
                    <div className="flex flex-col 2xl:flex-row md:gap-8">
                        <FormEjercicio
                            exerciseData={exerciseData}
                            handleChange={handleChange}
                            image={[setImage, imagePreview, setImagePreview]} />
                        <div className="max-w-3xl p-6 bg-white shadow-md w-full md:rounded-lg md:w-fit h-full">
                            <div className="flex flex-col gap-5 mb-4">
                                <div className='w-64 md:48'>
                                    <div className='mb-2'>
                                        <label className="block">
                                            <span className="text-gray-700">Tipo:</span>
                                            <select
                                                name="tipo"
                                                value={exerciseData.tipo}
                                                onChange={handleChange}
                                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                                            >
                                                <option value="0">Juego Colectivo</option>
                                                <option value="1">Tec-Tac</option>
                                                <option value="2">Juego</option>
                                                <option value="3">Preparación Física</option>
                                                <option value="4">Coordinación</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                {exerciseData.tipo == 0 ? (
                                    <FormJuegoColectivo exerciseData={exerciseData} handleChange={handleChange} />
                                ) : exerciseData.tipo == 1 ? (
                                    <FormTecTac exerciseData={exerciseData} handleChange={handleChange} />
                                ) : (<></>)}
                            </div>
                        </div>
                    </div>
                    <div className='mt-6 w-full flex justify-center'>
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Guardar Ejercicio
                        </button>
                    </div>
                </form>
                {modal && (
                    <Modal title={dataModal} isProgressActive={true} />
                )}
                {showAlert && (
                    <Alert title={"Introduce una imagen"} isProgressActive={false} setShowAlert={setShowAlert} />
                )}
            </div>
        </div>
    );
};