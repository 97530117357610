import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function ArrowBack({ route }) {
    const navigate = useNavigate();

    return (
        <button variant="ghost" size="icon" className="rounded-button"
            onClick={() => navigate(route)}>
            <span className="icon-[material-symbols--arrow-left-alt-rounded] size-6"></span>
        </button>
    )
}