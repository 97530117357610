import ProgressBar from "./ProgressBar";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Modal({ title, text, isProgressActive }) {
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false); // Controla la visibilidad con opacidad

    useEffect(() => { setIsVisible(true) }, []);

    return (
        <div className="absolute top-0 w-full h-full bg-opacity-25 bg-black center-content">
            <div className={`gap-4 p-8 bg-white rounded-lg transition-all duration-500 transform ${isVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-90'}`}
                style={{ transitionTimingFunction: 'ease-in-out' }}>
                {title != false ? (
                    <div className="flex flex-col items-center justify-center">
                        <span className="icon-[ph--check-circle-bold] size-12 bg-green-500"></span>
                        <p className="text-xl font-medium mt-2">{title}</p>
                        {text != null && text != "" && <p className="mt-3">{text}</p>}
                        {isProgressActive && (
                            <>
                                <ProgressBar duration={20} actionHandler={() => { navigate("/exercises") }} color={"#3b82f6"} />
                                <p className="text-muted-foreground">Volviendo a la selección de ejercicios</p>
                            </>
                        )}
                    </div>
                ) : (
                    <span className="icon-[line-md--loading-alt-loop]"></span>
                )}
            </div>
        </div>
    )
}