import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ProfileButton() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false)
  const modalRef = useRef(null)
  const buttonRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && buttonRef.current &&
        !modalRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)) {
        setIsModalOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="size-12 flex items-center justify-center relative w-fit">
      <button
        ref={buttonRef}
        className="bg-slate-900 size-10 rounded-full overflow-hidden focus:outline-none ring-2 ring-gray-300 focus:ring-gray-100 transition-transform duration-200 ease-in-out transform hover:scale-110"
        onClick={() => setIsModalOpen(!isModalOpen)}
      >
        <img
          src="/placeholder.svg?height=40&width=40"
          alt="Perfil"
          className="w-full h-full object-cover"
        />
      </button>

      <div
        ref={modalRef}
        className={`absolute z-10 top-[100%] right-0 w-48 bg-white shadow-lg rounded-b-md overflow-hidden transition-all duration-300 ease-in-out transform ${isModalOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
          }`}
      >
        <div className="py-2">
          <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200 w-full text-left"
            onClick={() => navigate("/")}>Mi Perfil</button>
          <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200 w-full text-left"
            onClick={() => navigate("/")}>Configuración</button>
          <button className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 transition-colors duration-200 w-full text-left"
            onClick={() => navigate("/")}>Cerrar sesión</button>
        </div>
      </div>
    </div>
  )
}